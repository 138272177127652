import Vue from 'vue';
import { config } from './config';

async function loadConfig() {
  try {
    const body = await fetch('/api.json');
    const json = await body.json();
    config.apiUrl = json.url;
    config.apiCode = json.code;
    config.apiVersion = json.version;
    config.oauth.clientId = json.clientId;
    config.oauth.tenantId = json.tenantId;
  } catch (e) {
    config.apiUrl = 'http://localhost';
    config.apiVersion = 'Not settle';
  }
  if (config.apiUrl.endsWith('/')) {
    config.apiUrl = config.apiUrl.substring(0, config.apiUrl.length - 1);
  }
  config.baseUrl = config.apiUrl + '/api/' + config.apiVersion;
}

const init = async () => {
  Vue.config.productionTip = false;
  Vue.prototype.$appVersion = process.env.VUE_APP_VERSION;

  await loadConfig();
};

export default init;
