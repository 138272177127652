import store, { mutationTypes } from '@/store';

const module = 'project';

export const project = {
  calculateForecast,
  // state: store.state.project.project,
  [module + 'State']: store.state[module],
  // loadingForecast: store.state.project.loadingForecast,
  // forecast: store.state.project.forecast,
  // config: store.state.project.project.,
  // preferences: store.state.project.project.config.preferences,
  exportForecastToExcel,
  loadProject,
  deleteProject,
  setProject,
  storeProject,
  createNewProject,
};

function calculateForecast(params) {
  return store.dispatch(`${module}/calculateForecast`, params);
}

function loadProject(id) {
  return store.dispatch(`${module}/loadProject`, id);
}

function deleteProject(id) {
  return store.dispatch(`${module}/deleteProject`, id);
}

function setProject(payload) {
  return store.commit(
    `${module}/${mutationTypes.project.SET_PROJECT}`,
    payload
  );
}

function storeProject(payload) {
  setProject(payload);
  return store.dispatch(`${module}/storeProject`, payload);
}

function createNewProject() {
  return store.dispatch(`${module}/createNewProject`);
}

function exportForecastToExcel(data) {
  return store.dispatch(`${module}/exportToExcel`, data);
}
