import Vue from 'vue';
import moment from 'moment';
import { numberFormat } from '@/global/common';

const FormatterMixin = Vue.mixin({
  filters: {
    date(value, format) {
      let defaultFormat = format || 'DD-MM-YYYY';

      if (value == null || value === '') return '-';
      else if (value && moment(value).isValid()) {
        return moment(value).format(defaultFormat);
      } else return value;
    },
    currency(value, currency, fractionDigits) {
      let defaultCurrency = currency || 'GBP';
      let defaultFractionDigits = fractionDigits != null ? fractionDigits : 2;

      if (value == null || value === '') return '-';
      else if (!isNaN(value) && defaultCurrency !== '') {
        return new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: defaultCurrency,
          minimumFractionDigits: defaultFractionDigits,
          maximumFractionDigits: defaultFractionDigits,
        }).format(value);
      } else return value;
    },
    percentage(value, fractionDigits = 2) {
      if (value == null || value === '' || isNaN(value)) return '-';

      value = (value * 100).toFixed(fractionDigits);

      if (value && !isNaN(value)) {
        return `${value}%`;
      } else return value;
    },
    numberFormat,
  },
});

export default FormatterMixin;
