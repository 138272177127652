import Vue from 'vue';
import Vuex from 'vuex';

import { app } from './app.module';
import { users } from './users.module';
import { projectList } from './projectList.module';

import { project, mutationTypes as projectMT } from './project.module';

import { catalog, mutationTypes as catalogMT } from './catalog.module';

Vue.use(Vuex);

export const mutationTypes = {
  project: projectMT,
  catalog: catalogMT,
};

export default new Vuex.Store({
  modules: {
    app,
    users,
    projectList,
    project,
    catalog,
  },
});
