<template>
  <div id="app">
    <v-app id="inspire">
      <v-app-bar app clipped-left clipped-right color="blue-grey darken-3" dark>
        <v-app-bar-nav-icon @click.stop="showLeftMenu(!app.leftMenu.show)" />
        <img src="@/assets/logo.png" alt="Reckitt Benckiser" class="logo" />
        <div class="d-flex display-1 ml-3 mr-5 font-italic font-weight-light">
          NPD Forecast Plus
        </div>
      </v-app-bar>
      <!-- LEFT MENU -->
      <v-navigation-drawer
        v-model="app.leftMenu.show"
        app
        clipped
        disable-resize-watcher
        color="grey lighten-4"
      >
        <v-card outlined class="header-menu">
          <v-card-text class="pb-2">
            <div class="header-menu-logo">
              <img
                src="@/assets/sub-logo.png"
                alt="Data Analytics"
                class="mr-auto ml-auto"
              />
            </div>
            <v-row justify="center">
              <div class="d-flex caption mt-1 font-weight-medium">
                Dynamic Forecasting
              </div>
            </v-row>
          </v-card-text>
        </v-card>
        <v-list dense class="grey lighten-4">
          <template v-for="(item, i) in items">
            <v-layout v-if="item.heading" :key="i" row align-center>
              <v-flex xs6>
                <v-subheader v-if="item.heading">{{
                  item.heading
                }}</v-subheader>
              </v-flex>
            </v-layout>

            <v-divider
              v-else-if="item.divider"
              :key="i"
              dark
              class="my-3"
            ></v-divider>

            <v-list-group
              v-else-if="item.children"
              :key="item.text"
              v-model="item.model"
              :prepend-icon="item.model ? item.icon : item['icon-alt']"
              append-icon
              :value="item.active"
              :to="item.path == '#' ? '' : item.path"
              :exact="item.exact"
            >
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <!-- <v-list-item v-for="(child, i) in item.children" :key="i" @click> -->
              <v-list-item v-for="(child, i) in item.children" :key="i">
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ child.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <!-- <v-list-item v-else :key="i" @click> -->
            <v-list-item
              v-else
              :key="item.text"
              :value="item.active"
              :to="item.path == '#' ? '' : item.path"
              :exact="item.exact"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="grey--text">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-footer absolute class="footer">
          <v-card class="flex  footer" flat tile>
            <v-card-text class="py-2  text-center">
              <v-container class="pa-0">
                <v-row justify="center" no-gutters>
                  <v-col cols="12">
                    <div
                      class="app-version text--lighten-3 blue-grey--text text-center"
                    >
                      version: {{ $appVersion }}
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="footer-logo">
                      <span>Powered by </span>
                      <img
                        src="@/assets/anchormen-logo.svg"
                        alt="anchormen logo"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-footer>
      </v-navigation-drawer>
      <!-- RIGHT MENU -->
      <v-navigation-drawer
        :value="showRightMenu"
        :width="app.rightMenu.width"
        app
        clipped
        right
        absolute
        disable-resize-watcher
        style="height:100% !important"
      >
        <v-row justify="center" class="pt-5 title">{{
          app.rightMenu.title
        }}</v-row>
        <slot name="rightmenu"></slot>
      </v-navigation-drawer>

      <v-main>
        <notification />
        <v-container fluid fill-height class="grey lighten-4">
          <v-layout justify-center>
            <v-flex>
              <slot />
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Notification from '@/components/common/notification';

export default {
  components: {
    Notification,
  },
  props: {
    withRightMenu: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    drawer: null,
    items: [
      {
        icon: 'mdi-file-table-box-outline',
        text: 'List of Projects',
        path: '/forecast/list',
      },
      {
        divider: true,
      },
      {
        icon: 'exit_to_app',
        text: 'Sign Out',
        path: '/logout',
      },
    ],
  }),
  computed: {
    showRightMenu() {
      return this.app.rightMenu.show && this.withRightMenu;
    },
    ...mapState({
      app: state => state.app,
    }),
  },
  methods: {
    ...mapActions('app', ['showLeftMenu']),
  },
};
</script>

<style scoped lang="scss">
.lighten-4 {
  background-color: blue;
}

.logo {
  width: 3em;
}

.header-menu {
  &-logo {
    display: flex;
    flex-direction: row;
    // height: 68px;
    height: auto;
    img {
      // height: auto;
      // max-height: 36px; //safari no way to test
      width: 7rem;
    }
  }
  &-am-logo {
    display: flex;
    flex-direction: row;

    span {
      font-size: 0.7rem;
      margin-right: 1rem;
    }
    img {
      height: auto;
      width: 5rem;
    }
  }
}

.footer {
  background-color: transparent;
  &-logo {
    display: flex;
    flex-direction: row;

    span {
      font-size: 0.7rem;
      margin-right: 1rem;
    }
    img {
      height: auto;
      width: 5rem;
    }
  }
}
.app-version {
  position: fixed;
  bottom: 0;
  width: fit-content;
  font-size: 10px;
  font-style: italic;
}
</style>
