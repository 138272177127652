import API from './API.service';

export const projectService = {
  create,
  get,
  update,
  remove,
  getAll,
  getForecastTable,
  exportToExcel,
};

function create(project) {
  let endpoint = `/project`;

  return API.post(endpoint, project);
}

function get(projectId) {
  let endpoint = `/project/${projectId}`;
  return API.get(endpoint);
}

function update(project) {
  let endpoint = `/project/${project.id}`;
  return API.put(endpoint, project);
}

function remove(projectId) {
  let endpoint = `/project/${projectId}`;
  return API.delete(endpoint);
}

function getAll() {
  let endpoint = `/project/list`;
  return API.get(endpoint);
}

function getForecastTable(project) {
  let endpoint = `/project/forecast`;
  return API.post(endpoint, project);
}

function exportToExcel(project) {
  let endpoint = `/project/export`;
  return API.postFile(endpoint, project);
}
