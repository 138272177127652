import store from '@/store';

const module = 'catalog';

export const catalog = {
  getAll,
  state: store.state[module],
};

function getAll() {
  return store.dispatch(`${module}/getCatalogs`);
}
