// DO NOT configure values here use the public/api.json to specify a middleware, main.js wil populate these values.
export const config = {
  apiUrl: null,
  apiVersion: null,
  apiCode: null,
  oauth: {
    clientId: null,
    tenantId: null,
  },
};
