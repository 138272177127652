import Vue from 'vue';
import { config } from '@/config';
import { authService } from '@/services';
import axios from 'axios';

export default {
  async axios() {
    // const idToken = await authService.getIdToken();
    const axiosInstance = axios.create({
      baseURL: config.apiUrl,
      headers: {
        ...config.headers,
        'Access-Control-Allow-Origin': '*',
        'content-type': 'application/json',
        // Authorization: `Bearer ${idToken.rawIdToken}`,
      },
      params: {
        code: config.apiCode,
      },
    });

    return axiosInstance;
  },
  async delete(endpoint, data) {
    const axiosInstance = await this.axios();
    return axiosInstance
      .delete(endpoint, { data: data })
      .then(res => {
        return res.data;
      })
      .catch(this.errorCatcher);
  },
  async patch(endpoint, data) {
    const axiosInstance = await this.axios();
    return axiosInstance
      .patch(endpoint, data)
      .then(res => {
        return res.data;
      })
      .catch(this.errorCatcher);
  },
  async post(endpoint, data) {
    const axiosInstance = await this.axios();
    return axiosInstance
      .post(endpoint, data)
      .then(res => {
        return res.data;
      })
      .catch(this.errorCatcher);
  },
  async postFile(endpoint, data) {
    const axiosInstance = await this.axios();
    return axiosInstance
      .post(endpoint, data, {
        responseType: 'blob',
      })
      .then(res => {
        return res.data;
      })
      .catch(this.errorCatcher);
  },
  async put(endpoint, data) {
    const axiosInstance = await this.axios();
    return axiosInstance
      .put(endpoint, data)
      .then(res => {
        return res.data;
      })
      .catch(this.errorCatcher);
  },
  async get(endpoint, config) {
    const axiosInstance = await this.axios();
    return axiosInstance
      .get(endpoint, config)
      .then(res => {
        return res.data;
      })
      .catch(this.errorCatcher);
  },
  errorCatcher(err) {
    let errorMsg = '';
    if (err.response && err.response.status) {
      let status = err.response.status;

      switch (status) {
        case 400: {
          let data = err.response.data;
          let messages = data.message ? `${data.message}. ` : '';
          if (data.subErrors && data.subErrors.length) {
            data.subErrors.forEach(subError => {
              messages += subError.message + ' ';
            });
          }

          errorMsg = 'Bad request. ';

          if (messages && messages.length) {
            errorMsg += messages;
          } else {
            errorMsg += 'Something went wrong...';
          }
          break;
        }
        case 401:
        case 403:
          errorMsg = `Your session has expired (code: ${status}). You will be redirected to the login.`;
          setTimeout(() => {
            authService.login();
          }, 2000);
          break;
        case 500:
          errorMsg =
            'An unexpected error occurred on the server (code: 500). Please contact your administrator.';
          break;
        case 503:
          errorMsg =
            'Service is unavailable (code: 503). Try again in a few minutes.';
          break;
        default:
          errorMsg = `Something when wrong. code: ${status}`;
          break;
      }

      Vue.prototype.$notify({
        title: 'Network Error',
        text: errorMsg,
        type: 'error',
        width: 800,
        duration: -500,
      });

      throw { status, message: errorMsg };
    } else {
      errorMsg = 'Something unexpected happened...';
      Vue.prototype.$notify({
        title: 'Unexpected Error!',
        text: errorMsg,
        type: 'error',
      });
    }
  },
};
