export const createProjectStructure = () => ({
  dump_date: null,
  category: null,
  segment: null,
  brand: null,
  test_country: null,
  bases_concept_id: null,
  name: null,
  cps: null,
  trial_tried_product: null,
  trial_packs_purchased: null,
  fga_volume: null,
  fga_value: null,
  plan_marketing_spend: null,
  plan_distribution: null,
  id: null,
  repeat_pack_buy_each: null,
  repeat_purchases_per_hh: null,
  repeat_at_least_once: null,
  bases_volume_forecast: null,
  sova: null,
  // bases_volume_forecast: 7000000,
  // sova: 0.07,
  updated_on: null,
  created_on: null,
  deleted: false,
  deleted_on: null,
  created_by: {
    id: null,
    fullname: null,
  },
  selected_countries: [],
  skus: [],
  calculation_data: [],
  status: 1, //OPEN: int = 1  FINALIZED: int = 2  ARCHIVED: int = 3
  step: 0,
});
