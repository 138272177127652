import { projectService, authService } from '@/services/';
import { MAT, MONTHLY } from '@/global/groundForecast';
import _ from 'lodash';

import { createProjectStructure } from '@/global/projectModel';

const NEW_PROJECT = 'NEW_PROJECT';
const SET_PROJECT = 'SET_PROJECT';

const STORING_PROJECT = 'STORING_PROJECT';
const SET_FORECAST = 'SET_FORECAST';

const SET_ON_DIRTY = 'SET_ON_DIRTY';
const SET_OFF_DIRTY = 'SET_OFF_DIRTY';
const LOADING_PROJECT = 'LOADING_PROJECT';
const LOADING_FORECAST = 'LOADING_FORECAST';
const LOADING_TABLE = 'LOADING_TABLE';

const EXPORTING_TO_EXCEL = 'EXPORTING_TO_EXCEL';

export const mutationTypes = {
  NEW_PROJECT,
  SET_PROJECT,
  SET_ON_DIRTY,
  SET_OFF_DIRTY,
};

const createForcastData = () => {
  return {
    forecast_data: [],
    test_country: [],
  };
};

const state = {
  project: createProjectStructure(),

  forecast: createForcastData(),

  dirty: false,

  applyingConfiguration: false,
  storing: false,
  loading: false,
  loadingForecast: false,
  loadingTable: false,

  exportingToCSV: false,
  exportingToExcel: false,
  exportingFinetuningToCSV: false,
};

const baseSettings = (state, rootGetters) => {
  return {
    projectId: state.project.id,
    groundForecastId: state.project.groundForecastId,
    appliedScenarios: rootGetters['scenario/activeScenarioIds'],
    config: state.project.config.preferences,
  };
};

const getters = {
  heatMapSettings: (state, getters, rootState, rootGetters) => {
    return {
      ...baseSettings(state, rootGetters),
      viewType: MAT,
    };
  },
  fineTuningTemplateSettings: (state, getters, rootState, rootGetters) => {
    return {
      ...baseSettings(state, rootGetters),
      viewType: MONTHLY,
    };
  },
  tableSettings: (state, getters, rootState, rootGetters) => {
    return {
      ...baseSettings(state, rootGetters),
      viewType: state.project.config.tableView,
    };
  },
  forcastParameters: state => {
    return {
      dump_date: state.project.dump_date,
      category: state.project.category,
      segment: state.project.segment,
      brand: state.project.brand,
      test_country: state.project.test_country,
      fga_value: state.fga_value,
      bases_volume_forecast: state.project.bases_volume_forecast,
      selected_countries: state.project.selected_countries,
      skus: state.project.skus,
    };
  },
  userInputCapture: state => {
    return {
      project_name: state.project.name,
      dump_date: state.project.dump_date,
      project_saved_on: state.project.updated_on
        ? state.project.updated_on
        : state.project.created_on,
      category: state.project.category,
      segment: state.project.segment,
      brand: state.project.brand,
      test_country: state.project.test_country,
      bases_concept_id: state.project.bases_concept_id,
      plan_marketing_spend: state.project.plan_marketing_spend,
      plan_distribution: state.project.plan_distribution,
      cps: state.project.cps,
      trial_tried_product: state.project.trial_tried_product,
      trial_packs_purchased: state.project.trial_packs_purchased,
      repeat_at_least_once: state.project.repeat_at_least_once,
      repeat_purchases_per_hh: state.project.repeat_purchases_per_hh,
      repeat_pack_buy_each: state.project.repeat_pack_buy_each,
      fga_volume: state.project.fga_volume,
      fga_value: state.project.fga_value,
      sova: state.project.sova,
      bases_volume_forecast: state.project.bases_volume_forecast,
    };
  },
};

const actions = {
  async createNewProject({ commit }) {
    commit(NEW_PROJECT);
    commit(SET_OFF_DIRTY);
  },

  async deleteProject({ state, dispatch }, project_id) {
    const projectToDelete = project_id ? project_id : state.project.id;

    return projectService.remove(projectToDelete).then(async () => {
      return dispatch('createNewProject');
    });
  },

  async storeProject({ state, commit }, project) {
    commit(STORING_PROJECT, true);
    let result;
    const account = authService.getAccount();

    const { deleted, deleted_on, ...rest } = project;
    deleted;
    deleted_on;

    const projectNormalized = {
      ...rest,
      created_by: {
        id: account.accountIdentifier,
        fullname: account.name,
      },
    };

    try {
      if (!projectNormalized.id) {
        // Create project
        const { id, created_on, ...projectRest } = projectNormalized;
        id;
        created_on;

        result = await projectService.create(projectRest);
        commit(SET_PROJECT, result);
      } else {
        // Update project
        const { created_on, ...projectRest } = projectNormalized;
        created_on;
        await projectService.update(projectRest);
      }

      commit(SET_OFF_DIRTY);
    } catch (e) {
      result = state.project;
      commit(STORING_PROJECT, false);
      throw e;
    } finally {
      commit(STORING_PROJECT, false);
    }

    return result;
  },

  async loadProject({ commit }, projectId) {
    commit(LOADING_PROJECT, true);

    try {
      const project = await projectService.get(projectId);

      commit(SET_PROJECT, project);
      commit(SET_OFF_DIRTY);
      commit(LOADING_PROJECT, false);
      //   //-----------LOADING FORECAST------------------
      //   await dispatch('scenario/getCurrentProjectScenarios', null, {
      //     root: true,
      //   });
      //   await dispatch('calculateForecast');
      //   //---------------------------------------------
    } finally {
      commit(LOADING_PROJECT, false);
    }
  },

  async calculateForecast({ commit /*, getters*/ }, params) {
    // const params = getters.forcastParameters;
    commit(LOADING_FORECAST, true);
    commit(SET_FORECAST, createForcastData());

    try {
      let result = await projectService.getForecastTable(params);
      commit(SET_FORECAST, result);
    } finally {
      commit(LOADING_FORECAST, false);
    }
  },

  exportToExcel({ commit, getters }, data) {
    commit(EXPORTING_TO_EXCEL, true);

    const fileName = generateFileName(state.project.name, 'xlsx');

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    return projectService
      .exportToExcel({
        forecast_data: data.forecastData,
        user_input_capture: getters.userInputCapture,
        test_country: data.test_country,
      })
      .then(exposeFile(fileName, fileType))
      .finally(() => commit(EXPORTING_TO_EXCEL, false));
  },
};

const mutations = {
  [NEW_PROJECT](state) {
    state.project = createProjectStructure();
    state.project.calculation_data = createForcastData();
    state.forecast = createForcastData(); //TODO: remove
    state.dirty = false;
  },

  [SET_PROJECT](state, project) {
    state.project = _.merge(state.project, project);
    state.dirty = true;
  },

  [STORING_PROJECT](state, storing) {
    state.storing = storing;
  },

  [SET_FORECAST](state, forecast) {
    state.forecast = forecast;
    state.project.calculation_data = forecast;
  },

  [LOADING_FORECAST](state, loading) {
    state.loadingForecast = loading;
  },

  [LOADING_PROJECT](state, status) {
    state.loading = status;
  },

  [LOADING_TABLE](state, loading) {
    state.loadingTable = loading;
  },

  [SET_ON_DIRTY](state) {
    state.dirty = true;
  },

  [SET_OFF_DIRTY](state) {
    state.dirty = false;
  },

  [EXPORTING_TO_EXCEL](state, loading) {
    state.exportingToExcel = loading;
  },
};

export const project = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

const generateFileName = (name, extension) => {
  const currentDate = new Date();

  const fileName = `${
    name ? name : 'unnamed_project_'
  }_${currentDate.getFullYear()}${currentDate.getMonth()}${currentDate.getDay()}.${extension}`;

  return fileName;
};

const exposeFile = (fileName, type) => data => {
  var fileURL = window.URL.createObjectURL(
    new Blob([data], {
      type,
    })
  );
  var fileLink = document.createElement('a');

  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);

  fileLink.click();
};
