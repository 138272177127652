import Vue from 'vue';
import App from './App.vue';
import Router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Notifications from 'vue-notification';
import FieldRulesMixin from './mixins/fieldRules.mixin';
import FormatterMixin from './mixins/formatter.mixin';
import init from './init';
import './plugins/compositionApi';
import {} from './directives/bubble.directive';

import LoginLayout from './views/layouts/LoginLayout.vue';
import MainLayout from './views/layouts/MainLayout.vue';

// import { config } from './config';
import { authService } from './services';

Vue.use(Notifications);

Vue.component('login-layout', LoginLayout);
Vue.component('main-layout', MainLayout);

Vue.config.productionTip = false;

/**
 * An async function to bootstrap the application.
 * We need to wait for all config from the middleware first.
 */
async function startVueApp() {
  await init();
  // Setup authentication service before creating the router instance.
  // The lib to connect to AD changes window.location.hash, which triggers
  // VueRouter navigation system. By initializing the router afterwards, the
  // lib have the chance to cleanup the hash (coming from redirected auth page)
  // without interfering with VueRouter.
  authService.setupAuth();

  new Vue({
    el: '#app',
    mixins: [FieldRulesMixin, FormatterMixin],
    router: Router.getInstance(),
    store,
    vuetify,
    render: h => h(App),
  });
}

// kickoff the bootstrapping.
startVueApp();
