import { config } from '@/config';
import * as msal from 'msal';

export const authService = {
  setupAuth,
  checkAuthenticated,
  login,
  logout,
  getIdToken,
  getAccessToken,
  getAccount,
};

// Global instance of MSAL library
let msalInstance = null;

/**
 * Create instance of MSAL and initialize it.
 * Since we're using loginRedirect, it also captures the information
 * comming from the redirected url and cleans it up when needed.
 */
function setupAuth() {
  msalInstance = new msal.UserAgentApplication({
    auth: {
      clientId: config.oauth.clientId,
      authority: `https://login.microsoftonline.com/${config.oauth.tenantId}`,
      redirectUri: `${location.protocol}//${location.host}/login`,
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: '',
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    system: {
      navigateFrameWait: 0, // don't wait to trigger hidden iframe flow
    },
  });

  // Library handling of redirect back after login.
  msalInstance.handleRedirectCallback(() => null);
}

function checkAuthenticated() {
  return msalInstance && !!msalInstance.getAccount();
}

function getAccount() {
  return msalInstance.getAccount();
}

function login() {
  msalInstance.loginRedirect({
    scopes: ['openid'],
  });
}

function logout() {
  msalInstance.logout();
}

function getIdToken() {
  const idRequest = {
    scopes: [config.oauth.clientId],
  };

  return msalInstance
    .acquireTokenSilent(idRequest)
    .then(tokenResponse => tokenResponse.idToken)
    .catch(() => {
      // Fallback to interaction when silent call fails.
      return msalInstance.acquireTokenRedirect(idRequest);
    });
}

function getAccessToken() {
  const accessRequest = {
    scopes: ['https://graph.windows.net/user.readbasic.all'],
  };

  return msalInstance
    .acquireTokenSilent(accessRequest)
    .then(tokenResponse => tokenResponse.accessToken)
    .catch(() => {
      // Fallback to interaction when silent call fails.
      return msalInstance.acquireTokenRedirect(accessRequest);
    });
}
